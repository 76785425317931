.companyContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  padding-left: 5px;
  padding-right: 5px;
}

.companyIntro {
  align-items: center;
  background-color: #fff;
  align-self: center;
  display: flex;
  width: 726px;
  max-width: 100%;
  flex-direction: column;
  color: #000;
  padding: 10px
}

@media (max-width: 991px) {
  .div, .companyContainer, .companyIntro  {
    padding: 0 20px;
  }

  .companyName, .introText, .sectionContent {
    max-width: 100%;
  }

  .companyName, .sectionContent {
    white-space: initial;
  }
}

.companyName {
  white-space: nowrap;
  font: 32px Lekton, sans-serif;
  font-weight: 700;
  margin: 0;
}

.companyLogo {
  aspect-ratio: 1.3;
  object-fit: contain;
  object-position: center;
  width: 273px;
  max-width: 100%;
}

.introText, .sectionContent {
  background-color: #fff;
  align-self: stretch;
  justify-content: center;
  text-align: center;
  font: 15px Lekton, sans-serif;
  font-weight: 700;
  padding: 0;
  margin-top: 0;
}

.sectionTitle {
  align-self: center;
  font: 24px Lekton, sans-serif;
  font-weight: 700;
  margin-bottom: 0;
}

.companyDetails {
  align-self: center;
  max-width: 1000px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whoSection, .whatSection, .whenSection, .whereSection, .howSection {
  text-align: center;
  color: #000;
}